<template>
    <div class="h-full my-auto">
        <v-container fluid class="h-full my-auto">
            <v-row class="h-full my-auto">
                <v-col cols="12" class="h-full my-auto">
                    <div
                        class="relative flex items-top justify-center h-full bg-gray-100 dark:bg-gray-900 sm:items-center py-4 sm:pt-0">
                        <div
                            class="min-w-screen overflow-x-hidden  my-auto  outline-none focus:outline-none  items-center flex justify-center">
                            <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                                <div class="">
                                    <div class="text-center p-5 flex-auto justify-center align-middle">
                                        <svg class="w-6 h-6 mx-auto" fill="none" stroke="currentColor"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122">
                                            </path>
                                        </svg>
                                        <h2 class="text-xl font-bold py-4 ">Set up payment</h2>
                                        <p class="text-sm text-gray-500 px-8">Please click the button below to set up
                                            your payment method. You will be redirected to a secure payment page to complete the process.
                                        </p>

                                        <v-row justify="center" class="p-5 flex flex-col">
                                            <v-col cols="3" class="mx-auto">
                                                <v-btn class="mx-auto mb-4" :loading="loading" block rounded dark color="red" @click="handleOnClick">Pay</v-btn>
                                            </v-col>
                                        </v-row>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// import axios from 'axios'
// import { Messages } from '@/utils/constants';

export default {
    name: 'Init',
    data() {
        return {
            loading: false,
            error: null,
        }
    },

    created() {
        // request link token from server
        // this.$store.dispatch('requestLinkToken', this.$session.get('user').id)

        if (this.$session.get('user').status === true) {
            this.$router.push('/')
        }
    },

    computed: {
    },

    watch: {
    },

    methods: {
        async handleOnClick() {
            // try {
            //     let payload = {
            //         user_id: this.$session.get('user').id,
            //     }
            //     const response = await axios.post(process.env.VUE_APP_API_URL +'/membership/checkout-session', payload, { headers: { "Content-Type": "application/json" } })
            //     window.location.href = response.data
            // } catch (error) {
            //     console.log(error)
            //     this.$router.push('/404')
            // }
        },
    }
}
</script>