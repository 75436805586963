<template>
    <v-container class="py-10 w-full">

        <v-row class="w-full flex justify-center">
            <div class="flex justify-center gap-4">
                    <v-card color="">
                                <v-card-title>
                                    <span class="headline">Alert Configuration</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container fluid>
                                        <div class="my-4">
                                            <div class="space-y-3">
                                                <div class="my-6">
                                                    <div class="my-4 flex justify-between">
                                                        <label class="block text-sm font-medium my-auto" for="merchantName">Alert me for my upcoming subscriptions</label>
                                                        <v-switch
                                                            color="red"
                                                            hide-details
                                                            class="my-auto"
                                                            v-model="formData.subscriptionReminders"
                                                        ></v-switch>
                                                    </div>
                                                    <div class="my-6">
                                                        <div class="my-4 flex justify-between">
                                                            <label class="block text-sm font-medium my-auto" for="amount">Account balance is less than </label>
                                                            <input placeholder="£200" v-model="formData.accountBalanceLessThan" id="amount" min="0" class="my-auto shadow border rounded-lg w-1/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" required />
                                                        </div>
                                                    </div>
                                                    <div class="my-6">
                                                        <div class="my-4 flex justify-between">
                                                            <label class="block text-sm font-medium my-auto" for="amount">Payment due tomorrow and account balance less than </label>
                                                            <input placeholder="£20" v-model="formData.accountBalanceLessThanAndDueTomorrow" id="amount" min="0" class="my-auto shadow border rounded-lg w-1/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" required />
                                                        </div>
                                                    </div>
                                                    <div class="my-6">
                                                        <div class="my-4 flex justify-between text-sm font-medium">
                                                            <span class="my-auto mr-2">When you spend more than: </span> 
                                                            <input v-model="formData.amountSpentInDays.amount" placeholder="£500" id="amount" min="0" class="my-auto shadow border rounded-lg w-1/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" required />
                                                            <span class="my-auto mx-2">in</span> 
                                                            <input v-model="formData.amountSpentInDays.days" placeholder="2" id="amount" min="0" class="my-auto shadow border rounded-lg w-1/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" required />
                                                            <span class="my-auto mx-2">days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-container>
                                </v-card-text>
                                
                                <v-card-actions>
                                    <div class="flex flex-col px-5 py-4 border-t border-slate-200">
                                        <div class="flex justify-between space-x-2">
                                            <v-btn class="mb-4" :loading="loading" small rounded dark color="red" @click="save">Save</v-btn>
                                        </div>
                                    </div>
                                </v-card-actions>
                            </v-card>
            </div>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            showForm: false,
            loading: false,
            formData: {
                amountSpentInDays: {
                    amount: 0,
                    days: 0
                },
                subscriptionReminders: false,
                accountBalanceLessThan: '',
                accountBalanceLessThanAndDueTomorrow: ''
            }
        }
    },
    methods: {
        hideForm() {
            this.showForm = false
        },
        save() {
            alert(JSON.stringify(this.formData))
        },
    }
}
</script>

<style>

</style>