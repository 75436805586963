<template>
    <v-container class="w-full h-full">
        <v-row class="md:flex md:justify-between md:items-center w-full px-4 my-4">
            <div>
                <div class="text-3xl font-bold text-black">Your Linked Banks</div>
            </div>
            <div class="justify-start md:justify-end my-4 md:my-0">
                <div class="flex gap-4">
                    <v-btn rounded color="teal"><span class="text-white">Add
                            Card</span><v-icon right color="white">mdi-plus</v-icon></v-btn>
                    <!-- <v-btn rounded color="teal" @click="requestLinkToken"><span class="text-white">Add
                            Card</span><v-icon right color="white">mdi-plus</v-icon></v-btn> -->
                </div>
            </div>
        </v-row>

        <v-row v-if="cards == undefined" class="w-full flex flex-col justify-center">
            <v-col cols="12" md="4" class="mx-auto w-full my-auto">
                <animated-bird></animated-bird>
            </v-col>
            <v-col cols="12" md="4" class="mx-auto w-full my-auto">
                <v-card-title>No Banks Linked ! Click the add button above to start linking your accounts...
                </v-card-title>
            </v-col>
        </v-row>

        <v-row v-else class="w-full flex flex-col justify-center">
            <v-expansion-panels popout>
                <v-expansion-panel v-for="(card, i) in cards"
                    class="flex flex-col my-2 col-span-full bg-white shadow-lg rounded" :key="i">
                    <v-expansion-panel-header class="px-5 py-6">
                        <div class="md:flex md:justify-between md:items-center">
                            <!-- Left side -->
                            <div class="flex items-center">
                                <div class="mr-4">
                                    <v-icon :color="mapCardStatusToColor(card.status)">mdi-credit-card-outline</v-icon>
                                </div>
                                <div>
                                    <div class="">{{ card.bank }} Bank</div>
                                </div>
                            </div>
                            <!-- Right side -->
                            <ul class="shrink-0 justify-start md:justify-end -space-x-3 -ml-px">
                                <div class="text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1"
                                    :class="mapCardStatusBgClasses(card.status)">
                                    {{ card.status }}</div>
                            </ul>
                        </div>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" color="black" class="ml-5">
                                $expand
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="my-4">
                        <div class="md:flex md:justify-between md:items-center mb-4">
                            <!-- Left side -->
                            <div class="flex items-center">
                                <v-card-subtitle><v-icon color="black">mdi-information-outline</v-icon> You can add/remove
                                    or reconnect expired accounts by clicking update button</v-card-subtitle>
                            </div>
                            <!-- Right side -->
                            <ul class="shrink-0 justify-start md:justify-end -space-x-3 -ml-px">
                                <div class="flex space-x-4">
                                    <v-btn disabled small rounded color="primary" @click="updateItem(card)">
                                        Update
                                        <v-icon right>
                                            mdi-reload
                                        </v-icon>
                                    </v-btn>
                                    <v-btn disabled small rounded color="error" @click="unlinkItem(card)">
                                        Remove
                                        <v-icon right>
                                            mdi-trash-can-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </ul>
                        </div>
                        <v-card-title class="mt-14">Accounts You Shared:</v-card-title>
                        <v-card-subtitle v-if="card.accounts == undefined || card.accounts.length <= 0">No accounts found.
                            If you have just linked a card,
                            please allow few seconds and try refreshing your page. If nothing changes, please contact our
                            support
                            team.</v-card-subtitle>
                        <div v-for="(account, i) in card.accounts" :key="i"
                            class="flex justify-between align-middle my-4 shadow-md">
                            <div class="flex">
                                <v-card-subtitle>{{ account.officialName }}</v-card-subtitle>
                                <v-card-subtitle>****{{ account.mask }}</v-card-subtitle>
                            </div>
                            <v-card-subtitle><span class="font-bold">£{{ account.balance }}</span></v-card-subtitle>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <alert-modal :dialog="deleteDialog" :message="'Deleting this will also remove all linked data. Are you sure?'"
                @confirmDelete="confirmDelete" @cancelDelete="cancelDelete"></alert-modal>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import AlertModal from '../../components/Partials/AlertModal.vue'
import AnimatedBird from '../../components/Partials/AnimatedBird.vue'
import { Messages } from '../../utils/constants'

export default {
    components: { AlertModal, AnimatedBird },
    data() {
        return {
            message: '',
            deleteDialog: false,
            deletingItem: null,
            // cards: [],
            cards: [
                {
                    "userId": 11,
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "bank": "Santander (UK)",
                    "status": "ACTIVE",
                    "balance": null,
                    "accounts": [
                        {
                            "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                            "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                            "balance": 100.0,
                            "mask": "0000",
                            "name": "Plaid Checking",
                            "officialName": "Plaid Gold Standard 0% Interest Checking",
                            "type": "DEPOSITORY",
                            "subtype": "CHECKING"
                        }
                    ]
                },
                {
                    "userId": 11,
                    "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
                    "bank": "Chase",
                    "status": "ACTIVE",
                    "balance": null,
                    "accounts": [
                        {
                            "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
                            "accountId": "rb8k6gMgVDsZQq8vJPAqtrpydQNkR9F7zwGm9",
                            "balance": 100.0,
                            "mask": "0000",
                            "name": "Plaid Checking",
                            "officialName": "Plaid Gold Standard 0% Interest Checking",
                            "type": "DEPOSITORY",
                            "subtype": "CHECKING"
                        },
                        {
                            "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
                            "accountId": "z3yp6aWaqruy8oB3lJmoFQLz693wGdSlnaejN",
                            "balance": 200.0,
                            "mask": "1111",
                            "name": "Plaid Saving",
                            "officialName": "Plaid Silver Standard 0.1% Interest Saving",
                            "type": "DEPOSITORY",
                            "subtype": "SAVINGS"
                        }
                    ]
                }
            ]
        }
    },

    mounted() {
        // this.getBanks()
    },

    computed: {
        userId() {
            return this.$session.get('user').id
        },
    },

    methods: {
        async requestLinkToken() {
            await this.$store.dispatch('requestLinkToken', this.userId)
            this.$router.push('/link')
        },
        async getBanks() {
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + '/items', { user_id: this.userId }, { headers: { "Content-Type": "application/json" } })
                this.cards = response.data.banks
            } catch (error) {
                this.$store.dispatch('showSnackBar', { status: true, message: error.response.data.error, type: Messages.ERROR })
            }
        },
        unlinkItem(item) {
            this.deletingItem = item
            this.deleteDialog = true
        },
        cancelDelete() {
            this.deleteDialog = false
        },
        async confirmDelete() {
            try {
                let payload = {
                    user_id: this.userId,
                    item_id: this.deletingItem.itemId
                }
                const response = await axios.post(process.env.VUE_APP_API_URL + '/items/delete', payload, { headers: { "Content-Type": "application/json" } })

                this.message = response.data
                this.deleteDialog = false
                this.getBanks()

                this.$store.dispatch('showSnackBar', { status: true, message: 'Item deleted successfully', type: Messages.SUCCESS })

                // refresh user token and data
                await this.$store.dispatch('refreshUserToken', this.userId)

            } catch (error) {
                this.deleteDialog = false
                this.message = error.data

                this.$store.dispatch('showSnackBar', { status: true, message: error.response.data.error, type: Messages.ERROR })
            }
        },
        async updateItem(item) {
            let payload = {
                user_id: this.userId,
                item_id: item.itemId
            }
            await this.$store.dispatch('requestUpdateToken', payload)
            this.$router.push('/update-item')
        },
        mapCardStatusBgClasses(status) {
            switch (status) {
                case 'ACTIVE':
                    return 'bg-green-100 text-green-600'
                case 'ERROR':
                    return 'bg-red-100 text-red-600'
                case 'PENDING':
                    return 'bg-yellow-100 text-yellow-600'
                default:
                    break;
            }
        },
        mapCardStatusToColor(status) {
            switch (status) {
                case 'ACTIVE':
                    return 'green'
                case 'ERROR':
                    return 'red'
                case 'PENDING':
                    return 'orange'
                default:
                    break;
            }
        }
    }
}
</script>

<style></style>